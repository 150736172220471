import Image from "next/image";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";

import { Color } from "../../../constants/Color";
import { FontType } from "../../../constants/Fonts";
import { getStart } from "../../../lib/date";
import { useMediaSize } from "../../../lib/hooks/useMediaSize";
import { Track } from "../../../types/domain/Track/Track";
import { Text } from "../../atoms/Text";
import {
	StyledCardInfoContainer,
	StyledCardInfoLowerContainer,
	StyledCardInfoUpperContainer,
	StyledCardWrapper,
	StyledCardWrapperSkeleton,
	StyledCountDownWrapper,
	StyledCountSkeleton,
	StyledCountValueWrapper,
	StyledCountWrapper,
	StyledCoverImageWrapper,
	StyledCoverOverlay,
	StyledCoverSkeleton,
	StyledCoverWrapper,
	StyledDescriptionSkeleton,
	StyledDisplayNameSkeleton,
	StyledMatic,
	StyledPriceSkeleton,
	StyledPriceWrapper,
	StyledTitleSkeleton,
	StyledTrackDisplayName,
	StyledTrackTitle
} from "./styles";

export interface TrackCardProps {
	track: Track;
	handleClick?: () => void;
}

export const TrackCard: React.FC<TrackCardProps> = ({ track, handleClick }) => {
	const beforeSale: boolean = track.startAt > new Date().getTime();
	const { isSm } = useMediaSize();
	const [isHovering, setIsHovering] = useState<boolean>(false);

	return (
		<StyledCardWrapper
			isMobile={isMobile}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			onClick={(e) => {
				e.stopPropagation();
				handleClick?.();
			}}
		>
			<StyledCoverWrapper>
				<StyledCoverImageWrapper>
					<Image
						src={`https://storage.googleapis.com/cypher-test-cover-image/${track.imageHash}`}
						layout="fill"
						alt={"image"}
					/>
				</StyledCoverImageWrapper>
				<StyledCoverOverlay isHovering={isHovering}></StyledCoverOverlay>
			</StyledCoverWrapper>
			<StyledCardInfoContainer>
				<StyledCardInfoUpperContainer>
					<div>
						<StyledTrackTitle isSm={isSm}>{track.title}</StyledTrackTitle>
						<StyledTrackDisplayName isSm={isSm}>
							{track.displayName}
						</StyledTrackDisplayName>
					</div>
				</StyledCardInfoUpperContainer>
				<StyledCardInfoLowerContainer>
					<StyledCountWrapper>
						{beforeSale ? (
							<>
								<Text fontType={FontType.EXSMALL} color={Color.WHITE_70}>
									販売数
								</Text>
								<StyledCountValueWrapper>
									<Text fontType={FontType.LARGE}>{track.quantity}</Text>
								</StyledCountValueWrapper>
							</>
						) : (
							<>
								<Text fontType={FontType.EXSMALL} color={Color.WHITE_70}>
									残り
								</Text>
								<StyledCountValueWrapper>
									<Text fontType={FontType.LARGE}>
										{track.quantity - track.ownerIds.length > 0
											? track.quantity - track.ownerIds.length
											: "Sold out"}
									</Text>
								</StyledCountValueWrapper>
							</>
						)}
					</StyledCountWrapper>
					{beforeSale ? (
						<StyledCountDownWrapper>
							<Text
								fontType={isSm ? FontType.SMALL : FontType.EXSMALL}
								color={Color.WHITE_100}
							>
								🎉 パーティーまで
							</Text>
							<Text fontType={FontType.LARGE} color={Color.MAIN}>
								{getStart(track.startAt)}
							</Text>
						</StyledCountDownWrapper>
					) : (
						<StyledPriceWrapper>
							<Text
								fontType={!isSm ? FontType.LARGE : FontType.SMALL}
								color={!isSm ? Color.MAIN : Color.WHITE_70}
							>
								¥{track.yen.toLocaleString()}
							</Text>
							{isSm && (
								<Text fontType={FontType.LARGE} color={Color.MAIN}>
									{track.price} <StyledMatic>MATIC</StyledMatic>
								</Text>
							)}
						</StyledPriceWrapper>
					)}
				</StyledCardInfoLowerContainer>
			</StyledCardInfoContainer>
		</StyledCardWrapper>
	);
};

export const TrackCardPlaceHolder: React.FC = () => {
	const { isSm } = useMediaSize();
	return (
		<StyledCardWrapperSkeleton isMobile={isMobile}>
			<StyledCoverSkeleton />
			<StyledCardInfoContainer>
				<StyledCardInfoUpperContainer>
					<StyledTitleSkeleton />
					<StyledDisplayNameSkeleton />
					{isSm && <StyledDescriptionSkeleton />}
				</StyledCardInfoUpperContainer>
				<StyledCardInfoLowerContainer>
					<StyledCountSkeleton />
					<StyledPriceSkeleton />
				</StyledCardInfoLowerContainer>
			</StyledCardInfoContainer>
		</StyledCardWrapperSkeleton>
	);
};
