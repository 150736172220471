import { css, keyframes } from "@emotion/react";

import { Color } from "../constants/Color";

export const skeletonAnimation = keyframes`
  0% {
    background-color: ${Color.SKELETON_2}
  }

  25% {
    background-color: ${Color.SKELETON_1}
  }

  50% {
    background-color: ${Color.SKELETON_1}
  }

  75% {
    background-color: ${Color.SKELETON_1}
  }

  100% {
    background-color: ${Color.SKELETON_2}
  }
`;

export const skeleton = css`
	animation: ${skeletonAnimation} 2s ease-in-out;
	animation-iteration-count: infinite;
`;
