import Head from "next/head";

export interface SeoProps {
	pageTitle?: string;
	pageDescription?: string;
	pagePath?: string;
	pageImgUrl?: string;
	pageImgWidth?: number;
	pageImgHeight?: number;
}

export const Seo: React.FC<SeoProps> = ({
	pageTitle,
	pageDescription,
	pagePath,
	pageImgUrl,
	pageImgWidth,
	pageImgHeight
}) => {
	const defaultTitle = "CYPHER";
	const defaultDescription =
		"CYPHER is a platform where artists and listeners build up together";
	const defaultImgUrl =
		"https://i.gyazo.com/32ad9a0449b2a7ec795a8d837b315be6.jpg";

	const title = pageTitle ? `${pageTitle} | ${defaultTitle}` : defaultTitle;
	const description = pageDescription ? pageDescription : defaultDescription;
	const url = pagePath;
	const imgUrl = pageImgUrl ? pageImgUrl : defaultImgUrl;
	const imgWidth = pageImgWidth ? pageImgWidth : 1200;
	const imgHeight = pageImgHeight ? pageImgHeight : 630;

	return (
		<Head>
			<title>{title}</title>
			<meta name="viewport" content="width=device-width,initial-scale=1.0" />
			<meta name="description" content={description} />
			<meta property="og:url" content={url} />
			<meta property="og:title" content={title} />
			<meta property="og:site_name" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={imgUrl} />
			<meta property="og:image:width" content={String(imgWidth)} />
			<meta property="og:image:height" content={String(imgHeight)} />
			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<link
				href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Noto+Sans+JP:wght@400;500;700&display=swap"
				rel="stylesheet"
			/>
			<link rel="canonical" href={url} />
		</Head>
	);
};
