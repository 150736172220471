export const getAgo = (past: number) => {
	const timeLeft = new Date().getTime() - past;
	if (timeLeft < 1000 * 60) {
		return `${Math.floor(timeLeft / 1000)}秒前`;
	} else if (timeLeft < 1000 * 60 * 60) {
		return `${Math.floor(timeLeft / 1000 / 60)}分前`;
	} else if (timeLeft < 1000 * 60 * 60 * 24) {
		return `${Math.floor(timeLeft / 1000 / 60 / 60)}時間前`;
	} else if (timeLeft < 1000 * 60 * 60 * 24 * 30) {
		return `${Math.floor(timeLeft / 1000 / 60 / 60 / 24)}日前`;
	} else {
		return `${Math.floor(timeLeft / 1000 / 60 / 60 / 24 / 30)}ヶ月前`;
	}
};

export const getStart = (start: number) => {
	let timeLeft = start - new Date().getTime();
	const days = Math.floor(timeLeft / 1000 / 60 / 60 / 24);
	timeLeft -= 1000 * 60 * 60 * 24 * days;
	const hours = Math.floor(timeLeft / 1000 / 60 / 60);
	timeLeft -= 1000 * 60 * 60 * hours;
	const minutes = Math.floor(timeLeft / 1000 / 60);
	timeLeft -= 1000 * 60 * minutes;
	return `${days}d ${hours}h ${minutes}m`;
};
