import styled from "@emotion/styled";
import tw from "twin.macro";

import { Color } from "../../../constants/Color";
import { FontType, applyFont } from "../../../constants/Fonts";
import { skeleton } from "../../../lib/skeletonAnimation";

export const StyledCardWrapper = styled.div<{ isMobile: boolean }>`
	${tw`relative flex rounded-lg box-border bg-transparent w-full sm:w-70 sm:h-120 sm:p-0 sm:flex-col cursor-pointer`}
	transition: background-color 0.05s ease-out;
	${({ isMobile }) => isMobile && tw`active:bg-white-100`}
`;

export const StyledCardWrapperSkeleton = styled.div<{ isMobile: boolean }>`
	${tw`relative flex rounded-lg box-border bg-transparent w-full sm:w-70 sm:h-120 sm:p-0 sm:flex-col`}
`;

// カバー画像
export const StyledCoverWrapper = styled.div`
	${tw`relative w-24 h-24 sm:w-70 sm:h-70`}
`;

export const StyledCoverImageWrapper = styled.div`
	${tw`rounded-lg relative w-24 h-24 sm:w-70 sm:h-70`}
	overflow: hidden;
`;

export const StyledCoverSkeleton = styled.div`
	${tw`relative w-24 h-24 sm:w-70 sm:h-70 rounded-lg`}
	${skeleton}
`;

export const StyledCoverOverlay = styled.div<{ isHovering: boolean }>`
	${tw`absolute rounded-lg top-0 left-0 w-24 h-24 sm:w-70 sm:h-70 bg-transparent`}
	transition: background-color 0.1s ease-out;
	${({ isHovering }) => isHovering && tw`bg-white-100`}
`;

export const PlayWrapper = styled.div`
	${tw`absolute`}
	top: 102px;
	left: 102px;
	pointer-events: none;
`;

export const StyledCardInfoContainer = styled.div`
	${tw`flex flex-col w-full h-24 ml-3 sm:ml-0 sm:mt-3 sm:h-42 justify-between sm:justify-start`}
`;

export const StyledCardInfoUpperContainer = styled.div`
	${tw`flex flex-col items-start`}
`;

export const StyledTrackTitle = styled.div<{ isSm: boolean }>`
	${({ isSm }) => applyFont(isSm ? FontType.MAIN : FontType.SMALL_BOLD)}
	color: ${Color.WHITE_100};
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export const StyledTitleSkeleton = styled.div`
	${tw`h-4 w-24 rounded-full mb-2`}
	${skeleton}
`;

export const StyledTrackDisplayName = styled.div<{ isSm: boolean }>`
	${({ isSm }) => applyFont(isSm ? FontType.SMALL : FontType.EXSMALL)}
	color: ${Color.WHITE_70};
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export const StyledDisplayNameSkeleton = styled.div`
	${tw`h-3 w-18 rounded-full`}
	${skeleton}
`;

export const StyledTrackDescription = styled.div`
	${tw`mt-3 text-white`}
	${applyFont(FontType.EXSMALL)};
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export const StyledDescriptionSkeleton = styled.div`
	${tw`h-14 w-full rounded-lg mt-3`}
	${skeleton}
`;

export const StyledCardInfoLowerContainer = styled.div`
	${tw`flex justify-between items-end w-full sm:mt-5`}
	bottom: 0;
	box-sizing: border-box;
`;

export const StyledCountDownWrapper = styled.div`
	${tw`flex flex-col items-end`}
	transform: translateY(2px);
`;

export const StyledCountSkeleton = styled.div`
	${tw`h-5 sm:h-10 w-12 sm:w-10 rounded-full sm:rounded-lg`}
	${skeleton}
`;

export const StyledPriceWrapper = styled.div`
	${tw`flex flex-col items-end`}
	transform: translateY(2px);
`;

export const StyledPriceSkeleton = styled.div`
	${tw`h-10 w-16 rounded-lg`}
	${skeleton}
`;

export const StyledLeftWrapper = styled.div`
	${tw`flex flex-col gap-1`}
`;

export const StyledMatic = styled.span`
	font-size: 14px;
`;

export const StyledCountWrapper = styled.div`
	${tw`flex sm:flex-col items-center sm:items-start`}
`;

export const StyledTypeContainer = styled.div`
	${tw`flex items-center sm:mt-2 mb-1 sm:mb-2`}
`;

export const StyledTypeCircle = styled.div<{ type: string }>`
	${tw`w-2 h-2 rounded-full border-solid border-4 mr-2`}
	${({ type }) =>
		type === "community"
			? tw`border-community`
			: type === "crowdfunding"
			? tw`border-crowdfunding`
			: tw`border-license`}
`;

export const StyledCountValueWrapper = styled.div`
	${tw``}
`;
